import { mapActions, mapGetters } from 'vuex'
const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot-password" */
    '@/components/forms/ForgotPassword'
  )
const SuccessForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot-password-success" */
    '@/components/forms/SuccessForgotPassword'
  )

export default {
  name: 'login-form',
  data() {
    return {
      email: '',
      password: '',
      typeInput: 'password',
      isTyping: false,
      visibleForgotPassModal: false,
      visibleSuccessReset: false,
      notification: null,
      resetPassEmail: null,
      selectedState: 'student',
      loginMessage: {
        student: 'Login to access G2Academy as a student.',
        alliance: 'Login to manage your partnership with G2Academy.'
      },
      availableState: ['student', 'alliance']
    }
  },
  components: {
    ForgotPassword,
    SuccessForgotPassword,
    TextField: () => import(/* webpackChunkName: "form" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    LinkButton: () => import(/* webpackChunkName: "button" */ '@/components/button/LinkButton'),
    Courses: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Courses'),
    Instructor: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Instructor'),
    Certificate: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Certificate')
  },
  computed: {
    ...mapGetters(['userData'])
  },
  watch: {
    password: function (val) {
      val.length > 0 ? (this.isTyping = true) : (this.isTyping = false)
    }
  },
  mounted() {
    const target = this.$route.hash && this.$route.hash.split('#')[1]
    if (target && this.availableState.includes(target)) {
      this.select(target)
    } else {
      this.select('student')
    }
  },
  methods: {
    ...mapActions('auth', ['loginStudent', 'loginMitra']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    select(state) {
      this.selectedState = state
      this.notification = null
      window.location.hash = state
    },
    userIsTyping(e) {
      if (e?.target?.value?.length > 0) {
        this.isTyping = true
      }
      this.isTyping = false
    },
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    successResetPassword(email) {
      this.resetPassEmail = email
      this.visibleForgotPassModal = false
      this.visibleSuccessReset = true
    },
    closeSuccessResetPassword() {
      this.visibleSuccessReset = false
    },
    toggleForgotPassModal() {
      this.visibleForgotPassModal = !this.visibleForgotPassModal
    },
    toggleRegister(activeState) {
      if (activeState === 'ALLIANCE') return this.$router.push('/register-alliance')
      this.email = ''
      this.password = ''
      this.notification = null
      this.$router.push('/register')
    },
    doLogin() {
      if (this.validateForm()) {
        let login = () => {}
        let payloads = {}
        switch (this.selectedState) {
          case 'alliance':
            login = this.loginMitra
            payloads = {
              email: this.email,
              password: this.password
            }
            break
          case 'student':
            login = this.loginStudent
            payloads = {
              email: this.email,
              password: this.password,
              formCode: 'studentLogin'
            }
            break
        }
        this.showLoading()
        login({
          payloads: payloads
        }).then(
          (response) => {
            this.loginSuccess(response)
          },
          () => {
            this.loginFail()
          }
        )
      }
    },
    loginSuccess(response) {
      if (response.data.code === 422) {
        const errorMessage = response.data.errors.error[0]
        this.notification = errorMessage
      } else {
        this.$emit('success', response.data.data, this.selectedState)
      }
      this.hideLoading()
    },
    loginFail() {
      this.hideLoading()
      this.notification = 'Terjadi kesalahan, harap coba lagi.'
    },
    validateForm() {
      // eslint-disable-next-line no-useless-escape
      const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
      if (this.email === '') {
        this.notification = 'Email is required!'
        return false
      } else if (!regex.test(this.email)) {
        this.notification = 'Email format is not valid!'
        return false
      } else if (this.password === '') {
        this.notification = 'Password is required!'
        return false
      } else {
        return true
      }
    },
    login() {
      window.open(process.env.VUE_APP_BASE_DOMAIN_ADMIN)
    }
  }
}
