export default {
  name: 'partner-navigation',
  props: {
    companyName: { type: String },
    picName: { type: String }
  },
  methods: {
    doLogout() {
      const utm = [localStorage.getItem('utm_campaign'), localStorage.getItem('utm_medium'), localStorage.getItem('utm_source')]
      localStorage.clear()
      if (utm[0]) {
        localStorage.setItem('utm_campaign', utm[0])
      }
      if (utm[1]) {
        localStorage.setItem('utm_medium', utm[1])
      }
      if (utm[2]) {
        localStorage.setItem('utm_source', utm[2])
      }
      this.$router.push('/')
    }
  }
}
