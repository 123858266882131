<template>
  <div class="login-form px-20 mdmax:px-0">
    <div class="text-3xl font-bold mb-8">Login</div>
    <div class="mb-6">
      <TextField type="text" :borderEnabled="true" placeholder="Input your email address" label="Email Address" v-model="payloads.email" :error="errors['email']" />
    </div>
    <div class="mb-6">
      <label class="text-xs pl-3 text-left block mb-1">Password</label>
      <div class="relative">
        <TextField
          :type="typeInput"
          :borderEnabled="true"
          id="password"
          name="password"
          placeholder="Input your password"
          v-model="payloads.password"
          @input="userIsTyping()"
          @keydown.enter="doLogin"
        />
        <img src="@/assets/images/icons/open-eye.svg" v-if="typeInput === 'password' && isTyping" @click="showPassword(true)" class="w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
        <img src="@/assets/images/icons/close-eye.svg" v-if="typeInput === 'text' && isTyping" @click="showPassword(false)" class="w-5 absolute right-4 top-1/2 transform -translate-y-1/2" />
      </div>
      <transition name="fade" mode="out-in">
        <div class="text-system-error text-left mt-1 text-xs" v-if="errors['password']">{{ errors['password'] }}</div>
      </transition>
    </div>
    <div class="text-right mb-8">
      <span class="inline-block text-yellow text-sm cursor-pointer" @click="resetPassword()">Reset Password</span>
    </div>
    <transition name="fade" mode="out-in">
      <div class="text-system-error text-center mb-6 mt-1 text-xs" v-if="generalError">{{ generalError }}</div>
    </transition>
    <div class="text-center mb-6">
      <Button buttonText="Login" size="big" @action="doLogin()" />
    </div>
    <div class="text-center text-sm">Don’t have clouducation account? <span class="text-yellow cursor-pointer">Register Now</span></div>
  </div>
</template>
<script>
import { isNotEmptyError } from '@/utils'
import { mapActions } from 'vuex'
const CONFIG = {
  email: 'Email',
  password: 'Password'
}
export default {
  components: {
    TextField: () => import(/* webpackChunkName: "textfield" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    coreClassId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    typeInput: 'password',
    isTyping: false,
    payloads: {
      password: '',
      email: ''
    },
    errors: [],
    generalError: ''
  }),
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('auth', ['loginEvent']),
    userIsTyping() {
      if (this.payloads.password.length > 0) {
        this.isTyping = true
        return
      }
      this.isTyping = false
    },
    showPassword(status) {
      status ? (this.typeInput = 'text') : (this.typeInput = 'password')
    },
    resetPassword() {
      this.$emit('reset')
    },
    doLogin() {
      if (this.validForm()) {
        // eslint-disable-next-line
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/
        if (!regex.test(this.payloads.email)) {
          this.errors['email'] = 'Email format is invalid!'
        } else {
          this.showLoading()
          this.loginEvent({
            payloads: {
              coreClassId: this.coreClassId,
              email: this.payloads.email,
              password: this.payloads.password
            }
          })
            .then((res) => {
              this.hideLoading()
              this.$emit('loginsuccess')
            })
            .catch((res) => {
              this.hideLoading()
              if (res.response.status === 400) {
                this.generalError = 'The email or password you entered is incorrect!'
              }
            })
        }
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payloads)
      this.errors = error.reduce(function (map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}
</script>
